import { useEffect, useState } from 'react';
import { MenuNode, hideElements, nodeElement } from '../modules/plan.service';
import { Tree } from 'primereact/tree';

interface MenuProps {
  elements?: nodeElement[];
}

export const Menu = ({ elements }: MenuProps) => {
  const [selectedKeys, setSelectedKeys] = useState<any>(null);

  useEffect(() => {
    if (elements && selectedKeys) {
      const checked: MenuNode[] = [];
      const datas = Object.entries(selectedKeys);

      if (datas.length > 0) {
        for (const index in datas) {
          const elmt = Object.entries(datas[index]);
          const key: any = elmt[0][1];
          const checkboxOption: any = elmt[1][1];

          if (checkboxOption?.checked)
            checked.push({
              key: key,
              checked: true,
            });
        }
      }
      hideElements(checked, elements);
    }
  }, [selectedKeys]);

  return (
    <div className='card flex justify-content-center w-full md:w-18rem p-component'>
      <Tree
        header={
          <h3>
            Calques{' '}
            <i
              className='pi pi-info-circle geo-icon'
              title='Cocher le calque, ou groupe de calques, que vous désirez masquer.'
            ></i>
          </h3>
        }
        value={elements}
        selectionMode='checkbox'
        selectionKeys={selectedKeys}
        onSelectionChange={(e: any) => {
          setSelectedKeys(e.value);
        }}
      />
    </div>
  );
};
