import { useEffect, useState } from 'react';
import { nodeElement, parseDomElements } from '../modules/plan.service';
import { PlanSvg } from './helpers/plan';
import { v4 as uuidv4 } from 'uuid';
import { Menu } from './menu';
import { useLocation } from 'react-router-dom';
import { NotFound } from './notFound';

export const Main = () => {
  const GetPlanId = () => {
    const location = useLocation();
    return location.pathname.split('/')[1] ?? undefined;
  };

  const planId = GetPlanId();
  const path: string = `${process.env.PUBLIC_URL}/plans/${planId}.svg`;
  const id = uuidv4();
  const [completed, setCompleted] = useState(false);
  const [elements, setElements] = useState<nodeElement[] | undefined>([]);

  const [notFoundError, setNotFoundError] = useState(false);

  const populateMenu = () => {
    const elements = parseDomElements(id);
    setElements(elements);
  };

  useEffect(() => {
    if (completed === true && planId) {
      populateMenu();
    }
  }, [completed]);

  return !notFoundError ? (
    <>
      <aside className='menu'>
        <Menu elements={elements} />
      </aside>
      <section className='plan'>
        <PlanSvg
          uid={id}
          name={path}
          className={'card p-component flex justify-content-center'}
          onCompleted={() => setCompleted(true)}
          onError={() => setNotFoundError(true)}
        />
      </section>
    </>
  ) : (
    <NotFound />
  );
};
