import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { Main } from './components/main';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <header className='flex flex-wrap'>
          <Header />
        </header>
        <div className='App-body'>
          <main className='content'>
            <Routes>
              <Route path='*' element={<Main />} />
              {/* <Route path='*' element={<NotFound />} /> */}
            </Routes>
          </main>
        </div>
      </BrowserRouter>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
