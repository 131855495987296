import * as React from 'react';
import logo from '../logo.svg';

export const Header = () => {
  return (
    <nav itemScope itemType='https://schema.org/SiteNavigationElement'>
      <img src={logo} className='App-logo' alt='logo Géo-Bio-Logis' />
      <h1>Géo-Bio-Logis - Plan</h1>
    </nav>
  );
};
