import { ElementType, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { v4 as uuidv4 } from 'uuid';

interface PlanSvgProps {
  name: string;
  className?: string;
  uid?: string;
  onLoad?: () => any;
  onCompleted?: (id: string) => any;
  onError?: () => any;
}

//https://github.com/tanem/react-svg
export const PlanSvg = ({
  name,
  className,
  uid,
  onLoad,
  onCompleted,
  onError,
}: PlanSvgProps) => {
  const id = uid ?? uuidv4();
  const ref = useRef(null);
  const [width, setWidth] = useState(0);

  const _errorElement = (): ElementType<any> | undefined => {
    console.log('errorElement fired');
    return undefined;
  };
  const _onError = (error: any): void => {
    console.log('onError fired', error);
    onError?.();
  };

  const _onLoad = (): any => {
    console.log('onLoad fired');
    return onLoad?.();
  };

  const _onCompleted = (): any => {
    console.log('onCompleted fired');
    return onCompleted?.(id);
  };

  return (
    <ReactSVG
      ref={ref}
      id={id}
      className={className}
      src={`${name}`}
      loading={() => (
        <div>
          <i className='pi pi-spin pi-spinner' style={{ fontSize: '10rem' }} />
        </div>
      )}
      fallback={_errorElement?.()}
      onError={(error) => _onError?.(error)}
      afterInjection={() => _onCompleted?.()}
      beforeInjection={() => _onLoad?.()}
    />
  );
};
