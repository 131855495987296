export interface nodeElement {
  key: string;
  label: string;
  children?: nodeElement[];
}

export interface MenuNode {
  key: string;
  checked: boolean;
}

/**
 * Recursive method to create treeview
 * @param nodes array list of HtmlElement
 * @returns array list of found node elements
 */
const loopOnDomElements = (
  nodes: HTMLCollection
): nodeElement[] | undefined => {
  let elements: nodeElement[] = [];

  for (let node of nodes) {
    let elementId = node.getAttribute('uuid');

    if (elementId) {
      const label = node.getAttribute('inkscape:label');

      if (label !== null) {
        elements.push({
          key: elementId,
          label: label,
          children: loopOnDomElements(node.children),
        });
      }
    }
  }
  return elements.length > 0 ? elements : undefined;
};

/**
 * Method to parse dom from root element to populate tree of desired elements
 * @param id the id attribute of root element
 * @returns the treeview
 */
export const parseDomElements = (id: string): nodeElement[] | undefined => {
  let elements: nodeElement[] | undefined = [];

  const rootELT = document.getElementById(id);
  const svg = document.getElementsByTagName('svg');
  if (rootELT !== null && svg !== undefined) {
    elements = loopOnDomElements(svg[0].children);
  }

  //console.log(elements);
  return elements !== undefined && elements.length > 0 ? elements : undefined;
};

/**
 * Recursive method to browse tree nodes and apply action on corresponding elements in DOM
 * @param searchedNodes
 * @param nodes
 * @param action
 */
const browseTreeNodeAndApply = (
  searchedNodes: MenuNode[],
  nodes: nodeElement[],
  action: (htmlElement: HTMLElement) => void,
  oppositeAction?: (htmlElement: HTMLElement) => void
) => {
  for (let node of nodes) {
    let elmt: any = document.querySelector(`[uuid=${node.key}]`);

    if (elmt) {
      if (searchedNodes.some((elmt) => elmt.key === node.key)) {
        action(elmt);
      } else if (oppositeAction !== undefined) {
        oppositeAction?.(elmt);
      }
    }

    if (node.children) {
      browseTreeNodeAndApply(
        searchedNodes,
        node.children,
        action,
        oppositeAction
      );
    }
  }
};

/**
 * Method to manage hide/show event of elements in plan, driven by menu
 * @param elements
 */
export const hideElements = (
  checkedElements: MenuNode[],
  allElements: nodeElement[]
) => {
  browseTreeNodeAndApply(
    checkedElements,
    allElements,
    (elmt: any) => {
      elmt.setAttribute('class', 'hidden');
    },
    (elmt: any) => {
      elmt.removeAttribute('class');
    }
  );
};
